
.elementor-section.bp-1200 {
  .elementor-container {

    //width: 100%;
    flex-wrap: wrap;
    /*display: block;
    @media (min-width: 1200px) {
      display: flex;
    }*/

    .elementor-column.elementor-col-50, .elementor-column[data-col="50"] {
      //flex: 1 0 0% !important;
      width: 100% !important;
      @media (min-width: 1200px) {
        width: 50% !important;
      }
    }
  }
}

.col-text .elementor-widget-wrap {
  padding: 40px 30px 50px 30px !important;
  @media (min-width: 768px) {
    padding: 90px 80px 70px 90px !important;
  }
}

.top-header {
  .elementor-element-populated {
    justify-content: flex-end;
    padding: 10px!important;
  }
}

@media (max-width: 767px) {
  h2.elementor-heading-title.elementor-size-default {
    font-size: 48px !important;
    line-height: 1.2 !important;
  }
}

.elementor-64 .elementor-element.elementor-element-88d9e3e .htbuilder-nav ul.htbuilder-mainmenu li a {
  padding: 0 5px 30px;
  font-size: 14px;
  @media (min-width: 1400px) {
    padding: 0 30px 30px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 1024px) {
  header .elementor-section-wrap section:nth-child(2) {
    padding: 40px 20px 0 20px !important;
  }
}

.tribe-events-view .tribe-events-pro-photo {
  width: 100% !important;
}

